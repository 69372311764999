import React from 'react'
import styled from 'styled-components'
import '../../../../../styles/spectre.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'

library.add(faChevronCircleDown)

const AccordionComponentShipmentItems = styled.div`
.es-c-accordion--shipment-items {
  // 🎛
  // 🎨

  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:hover {
  }
  // 🖋
  font-family: 'Open Sans';
    .es-accordion-wrapper {
    // 📰
    display: grid;
    grid-template-rows: 0.2fr 0.1fr;
    grid-template-columns: 1fr 1fr 0.3fr;
    grid-template-areas:
      "accordion_header"
      "accordion_body";

    .accordion-header {
      grid-area: accordion_header; // 🗺
      background-color: #505C61;
      color: #fff;
      border-radius: 3px 3px 0px 0px;
      // 💅
      transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
      
      .es-accordion-wrapper--header {
        min-width: 800px;
        min-height: 70px;
        display: grid;
        grid-template-rows: minmax(1fr, 1fr) 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 0.5fr;
        grid-template-areas:
          "sg_shipment_weight_title sg_pallets_title sg_skid_spots_title sg_carrot"
          "sg_shipment_weight sg_pallets sg_skid_spots sg_carrot"; 
        
        .es-sg-carrot {
          grid-area: sg_carrot; // 🗺
          align-self: center;
          justify-self: center;
          padding-left: 40px;
        }
        .es-sg-shipment-weight-title {
          grid-area: sg_shipment_weight_title; // 🗺
          padding-left: 18px;
          align-self: center;
          line-height: 24px;
          font-size: 14px;
        }
        .es-sg-shipment-weight {
          grid-area: sg_shipment_weight; // 🗺
          padding-left: 18px;
          align-self: center;
          font-size: 18px;
        }
        .es-sg-pallets-title {
          grid-area: sg_pallets_title; // 🗺
          padding-left: 18px;
          align-self: center;
          line-height: 24px;
          font-size: 14px;
        }
        .es-sg-pallets {
          grid-area: sg_pallets; // 🗺
          padding-left: 18px;
          align-self: center;
          font-size: 18px;
        }
        .es-sg-skid-spots-title {
          grid-area: sg_skid_spots_title; // 🗺
          padding-left: 18px;
          align-self: center;
          line-height: 24px;
          font-size: 14px;
        }
        .es-sg-skid-spots {
          grid-area: sg_skid_spots; // 🗺
          padding-left: 18px;
          align-self: center;
          font-size: 18px;
        }
      }  
    }
    .accordion-body {
      grid-area: accordion_body; // 🗺
      background-color: #E8EDF0;
      border: 1px solid #BBBBBB;
      border-radius: 0px 0px 3px 3px;
      .es-accordion-wrapper--body {
        padding-top: 20px;
        display: grid;
        grid-template-rows: 1fr 0.2fr 1fr 1fr;
        grid-template-columns: 1fr 0.4fr;
        grid-template-areas:
          "sg_title sg_title sg_title sg_title"
          "sg_freight_class_1 sg_freight_class_1 sg_freight_class_1 sg_freight_class_1"
          "sg_freight_class_2 sg_freight_class_2 sg_freight_class_2 sg_freight_class_2"
          "sg_footer_1 sg_footer_1 sg_footer_1 sg_footer_2"; 
        
        .es-sg-title {
          grid-area: sg_title; // 🗺
          align-self: center;
          line-height: 24px;
          font-size: 20px;
          color: #000;
          font-weight: 600;
          padding-left: 20px;
        }
        .es-sg-freight-class-1 {
          grid-area: sg_freight_class_1; // 🗺
          align-self: center;
          font-size: 18px;
          color: #000;
          font-weight: 400;
          padding-left: 20px;
        }
        .es-sg-freight-class-2 {
          grid-area: sg_freight_class_2; // 🗺
          align-self: center;
          font-size: 12px;
          color: #666666;
          font-weight: 500;
          padding-left: 20px;
        }
        .es-sg-footer-1 {
          grid-area: sg_footer_1; // 🗺
          font-size: 18px;
          color: #666;
          background-color: #FFF;
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 20px;
          span {
            color: #000;
            font-weight: 600;
          }
        }
        .es-sg-footer-2 {
          grid-area: sg_footer_2; // 🗺
          font-size: 18px;
          font-weight: 600;
          color: #000;
          background-color: #FFF;
          align-self: end;
          padding-top: 12px;
          padding-bottom: 12px;
          padding-right: 32px;
        }
      } 
    }
  }
}
`;

const AccordionHeader = styled.div`
    font-family: Teko,'Helvetica Neue',Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
`

const AccordionComponent = (props) => {
  return (
    <AccordionComponentShipmentItems>
      <AccordionHeader>{props.accordionHeader}</AccordionHeader>
      <div className="es-c-accordion--shipment-items">
        <div className="es-accordion-wrapper">
          <div className="accordion">
            <input
              type="checkbox"
              id="accordion-3"
              name="accordion-checkbox"
              hidden
            />
            {}
            <label className="accordion-header" htmlFor="accordion-3">
              {}
              <div className="es-accordion-wrapper--header">
                <div className="es-sg-carrot">
                  <FontAwesomeIcon icon="chevron-circle-down" />
                </div>
                <div className="es-sg-shipment-weight-title">
                  Total Shipment Weight
                </div>
                <div className="es-sg-shipment-weight">23 LBS</div>
                <div className="es-sg-pallets-title">
                  Total Handling Units (Pallets)
                </div>
                <div className="es-sg-pallets">Not Stackable</div>
                <div className="es-sg-skid-spots-title">Total Skid Spots</div>
                <div className="es-sg-skid-spots">4</div>
              </div>
            </label>
            {}
            <div className="accordion-body">
              {}
              <div className="es-accordion-wrapper--body">
                <div className="es-sg-title">Plastic Bags</div>
                <div className="es-sg-freight-class-1">55</div>
                <div className="es-sg-freight-class-2">Freight Class</div>
                <div className="es-sg-footer-1">
                  Weight: <span>23 LBS</span>
                </div>
                <div className="es-sg-footer-2">
                  Length: 5, Width: 5, Height: 4
                </div>
              </div>
            </div>
          </div>
          {}
        </div>
      </div>
  

    </AccordionComponentShipmentItems>
  )
}

export default AccordionComponent;