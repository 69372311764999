import React from 'react'
import styled from 'styled-components'
import '../../../../../styles/spectre.css';

const MoleculeShipmentDetails = styled.div` 
.es-cg-shipment-details-accordion {
    // 📰
    display: grid;
    margin-bottom: 20px;
    grid-template-rows: minmax(1fr, 1fr) 1fr;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas:
    "cg_special_instructions cg_reference_numbers cg_other_details";

    .cg-special-instructions {
      grid-area: cg_special_instructions; // 🗺
      .sg-row-1 {
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 12px;
        font-weight: 400;
        color: #2b363b;
        margin-bottom: 12px;
      }
      .sg-row-2 {
        font-size: 15px;
        font-weight: 400;
        color: #141b1f;
        margin-bottom: 10px;
      }
      .sg-row-3 {
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 12px;
        font-weight: 400;
        color: #2b363b;
        margin-bottom: 12px;
      }
      .sg-row-4 {
        font-size: 15px;
        font-weight: 400;
        color: #141b1f;
      }
    }
    .cg-reference-numbers {
      grid-area: cg_reference_numbers; // 🗺
      .sg-row-1 {
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 12px;
        font-weight: 400;
        color: #2b363b;
        margin-bottom: 12px;
      }
      .sg-row-2 {
        font-size: 15px;
        word-wrap: break-word;
        font-weight: 600;
        color: #666;
      }
      .sg-row-3 {
        font-size: 15px;
        word-wrap: break-word;
        font-weight: 600;
        color: #666;
      }
    }
    .cg-other-details {
      grid-area: cg_other_details; // 🗺
      margin-top: 28px;
      .sg-row-2 {
        font-size: 14px;
        line-height: 1.42857143;
        color: #768287;
      }
      .sg-row-3 {
        font-size: 14px;
        line-height: 1.42857143;
        color: #768287;
      }
    }
}
`;

const EchoShipMolecule = () => {
  return (
    <MoleculeShipmentDetails>
        <div className="es-sg-shipment-details-info">
          <div className="es-t-page-title">Shipment Details</div>
          <div className="es-c-shipment-details-accordion">
            <div className="es-cg-shipment-details-accordion">
              <div className="cg-special-instructions">
                <div className="sg-row-1">SPECIAL INSTRUCTIONS</div>
                <div className="sg-row-2">
                  IT TEST LOAD DO NOT BOOK IF YOU ARE IN CARRIER SALES, THANK YOU.
                  IT TEST LOAD DO NOT BOOK IF YOU ARE IN CARRIER SALES, THANK YOU.
                </div>
                <div className="sg-row-3">TRUCK EQUIPMENT</div>
                <div className="sg-row-4">Van 48'</div>
              </div>
              <div className="cg-reference-numbers">
                <div className="sg-row-1">Reference Numbers</div>
                <div className="sg-row-2">Load BOL #</div>
                <div className="sg-row-3">Order #</div>
                <div className="sg-row-4" />
              </div>
              <div className="cg-other-details">
                <div className="sg-row-1" />
                <div className="sg-row-2">APITEAMTEST02282019</div>
                <div className="sg-row-3">MANUAL</div>
                <div className="sg-row-4" />
              </div>
            </div>
          </div>
        </div>
    </MoleculeShipmentDetails>  
  )
}

export default EchoShipMolecule;