import React from 'react'
import styled from 'styled-components'

import ShipmentTrackerIndicatorOrdered from './Components/Indicator/ShipmentTrackerIndicatorOrdered.js'

import OrganismTrackShipmentSummary from './Components/OrganismTrackShipmentSummary.js'
import OrganismTrackShipmentRightContent from './Components/OrganismTrackShipmentRightContent.js'
import MoleculeShipmentDetails from './Components/MoleculeShipmentDetails.js'
import AccordionComponentCustom from './Components/AccordionComponentCustom.js'
import AccordionComponentShipmentItems from './Components/ShipmentItemsAccordion.js'

// Define CSS Grid Components
const GOrderedContentGrid = styled.div`
`;
const SgOrderedContentGrid = styled.div`
    // 📰
    max-width: 1360px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: minmax(0.4fr, 5fr) 1fr 0.4fr 1fr 1fr 5fr;
    grid-template-columns: 0.6fr 0.4fr;
    grid-template-areas:
    "g_shipment_tracker_indicator g_shipment_tracker_indicator"
    "g_status_banner g_status_banner"
    "g_shipment_summary ."
    "g_track_and_trace ."
    "g_shipment_info g_summary";
`;
const GShipmentTrackerIndicator = styled.div`
  grid-area: g_shipment_tracker_indicator; // 🗺
`;
const GShipmentStatusBanner = styled.div`
    grid-area: g_status_banner; // 🗺
    font-size: 14px;
    padding: 6px;
    color: #141b1f;
    background-color: #e7edf0;
    border: solid 1px #93afbf;
    border-radius: 2px;
    margin-top: 10px;
`;
const GTrackShipmentSummary = styled.div`
  grid-area: g_shipment_summary; // 🗺
  margin-top: 20px;
  margin-bottom: 40px;
`;
const GShipmentTrackAndTrace = styled.div`
    grid-area: g_track_and_trace; // 🗺
    font-family: Teko,'Helvetica Neue',Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
`;
const GShipmentInfo = styled.div`
    grid-area: g_shipment_info; // 🗺
    padding-top: 20px;
    margin-bottom: 100px;
`;
const GSummary = styled.div`
    grid-area: g_summary; // 🗺
    padding-left: 100px;
    padding-right: 70px;
    justify-content: end;
    align-content: end;
`;

const EchoShipGridComponentContent = () => {
  return (

    <GOrderedContentGrid>
        <SgOrderedContentGrid>

            <GShipmentTrackerIndicator>
                <ShipmentTrackerIndicatorOrdered></ShipmentTrackerIndicatorOrdered>
            </GShipmentTrackerIndicator>
            <GShipmentStatusBanner>
                <strong>Your shipment is scheduled:</strong> We have received your order.
            </GShipmentStatusBanner>

            <GTrackShipmentSummary>
                <OrganismTrackShipmentSummary></OrganismTrackShipmentSummary>
            </GTrackShipmentSummary>

            <GShipmentTrackAndTrace>
                Track And Trace
            </GShipmentTrackAndTrace>

            <GShipmentInfo>   

                <AccordionComponentCustom 
                  accordionTitle="Delivery Appointment Data and/or Time"
                  accordionSubTitle="Wed Mar 20 at 3:00 pm"
                  accordionTitleRight=""
                  accordionSubTitleRight=""
                  htmlFor="accordion-track-and-trace"
                />
                <AccordionComponentCustom 
                  accordionHeader="Origin"
                  accordionTitle="Test Account - City and Borough Of Juneau"
                  accordionSubTitle="Business"
                  accordionTitleRight="JUNEAU, AK"
                  accordionSubTitleRight="99801"
                  htmlFor="accordion-origin"
                />
                <AccordionComponentCustom 
                  accordionHeader="Destination"
                  accordionTitle="Test Account - Achorage City Hall"
                  accordionSubTitle="Business"
                  accordionTitleRight="ANCHORAGE, AK"
                  accordionSubTitleRight="99501"
                  htmlFor="accordion-destination"
                />
                <AccordionComponentShipmentItems 
                  accordionHeader="Shipment Items (4)"
                />
                <MoleculeShipmentDetails></MoleculeShipmentDetails>

                <AccordionComponentCustom 
                  accordionHeader="Carrier Information"
                  accordionTitle="Echo Global Logistics"
                  accordionSubTitle="Carrier"
                  accordionTitleRight=""
                  accordionSubTitleRight=""
                  htmlFor="accordion-carrier-info"
                />

            </GShipmentInfo>

            <GSummary>
                <OrganismTrackShipmentRightContent></OrganismTrackShipmentRightContent>
            </GSummary>

        </SgOrderedContentGrid>
    </GOrderedContentGrid>

  )
}

export default EchoShipGridComponentContent;