import React from 'react'
import styled from 'styled-components'


const OrganismTrackShipmentSummary = styled.div`
  
  .es-sg-shipment-summary {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    font-weight: 400;
    color: #141b1f;
    // 📰
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "sg_origin sg_estimated_pickup sg_destination sg_estimated_delivery";

    .es-sg-origin {
      grid-area: sg_origin; // 🗺
      span {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        color: #2b363b;
      }
    }
    .es-sg-estimated-pickup {
      grid-area: sg_estimated_pickup; // 🗺
      span {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        color: #2b363b;
      }
    }
    .es-sg-destination {
      grid-area: sg_destination; // 🗺
      span {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        color: #2b363b;
      }
    }
    .es-sg-estimated-delivery {
      grid-area: sg_estimated_delivery; // 🗺
      span {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        color: #2b363b;
      }
    }
  }
`;

const EchoShipOrganism = () => {
  return (

    <OrganismTrackShipmentSummary>

          <div className="es-sg-shipment-summary">
            <div className="es-sg-origin">
              <span>ORIGIN</span><br />
              PITTSBURGH, PA<br />
              15233
            </div>
            <div className="es-sg-estimated-pickup">
              <span>ESTIMATED PICKUP</span><br />
              Tue Mar 12<br />
              8:00 AM - 3:00 PM
            </div>
            <div className="es-sg-destination">
              <span>DESTINATION</span><br />
              WHEATON, IL<br />
              60187
            </div>
            <div className="es-sg-estimated-delivery">
              <span>ESTIMATED DELIVERY</span><br />
              Thu Mar 14
            </div>
          </div>

    </OrganismTrackShipmentSummary>

  )
}

export default EchoShipOrganism;