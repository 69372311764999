import React from 'react'
import styled from 'styled-components'
import '../../../../../styles/icons/css/icons.css';

import { Link } from 'gatsby'


const OrganismTrackShipmentRightContent = styled.div`
    .es-g-right-content 
    .es-sg-right-content {
      // 📰
      display: grid;
      grid-template-rows: 1fr 1fr 0.5fr;
      grid-template-columns: 1fr;
      grid-template-areas:
      "sg_map_route"
      "sg_shipment_options"
      "sg_shipment_documents";

      .es-sg-map-route {
        grid-area: sg_map_route; // 🗺
        max-height: 250px;
      }
      .es-sg-shipment-options {
        grid-area: sg_shipment_options; // 🗺
        margin-top: 50px;
      }
      .es-sg-shipment-documents {
        grid-area: sg_shipment_documents; // 🗺
        margin-top: 30px;
      }
    }

    .es-g-right-content 
    .es-sg-right-content {
      .es-sg-title {
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 12px;
          font-weight: 400;
          color: #2b363b;
      }
    }

    .es-g-right-content 
    .es-sg-right-content
    .es-c-shipment-options {
      img {
        object-fit: cover;
        height: 250px;
        width: 375px;
      }
    }

      .es-sg-shipment-options
      .es-c-shipment-options {
      .es-cg-copy-shipment a,
      .es-cg-share-bol a,
      .es-cg-notify-pickup a,
      .es-cg-watch-shipment a {
        // 📰
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        "sg_title sg_icon";

        .es-title {
          grid-area: sg_title; // 🗺
          text-decoration: none !important;
          color: #0033a1;
          font-size: 14px;
        }
        .es-icon i {
          grid-area: sg_icon; // 🗺
          align-self: end;
          color: #0033a1;
          padding-left: 180px;
        }
      }
    }

    .es-cg-copy-shipment a,
    .es-cg-share-bol a,
    .es-cg-notify-pickup a,
    .es-cg-watch-shipment a {
      color: #fff;
      margin-top :14px;
      border-bottom: solid 1px #bbb;
      padding-bottom: 10px;
    }

    // COMPONENT GRID - SHIPMENT OPTIONS 📦
    .es-sg-shipment-options
    .es-c-shipment-options {
      .es-cg-shipment-options {
        // 📰
        display: grid;
        grid-column-gap: 6px;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
        "cg_copy_shipment"
        "cg_share_bol"
        "cg_notify_pickup"
        "cg_watch_shipment";

        .es-cg-copy-shipment {
          grid-area: cg_copy_shipment; // 🗺
          transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
          &:hover a .es-icon i {
          color: #26c1fb;
          }
          &:hover a .es-title {
          color: #26c1fb;
          }
        }
        .es-cg-share-bol {
          grid-area: cg_share_bol; // 🗺
          transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
          &:hover a .es-icon i {
          color: #26c1fb;
          }
          &:hover a .es-title {
          color: #26c1fb;
          }
        }
        .es-cg-notify-pickup {
          grid-area: cg_notify_pickup; // 🗺
          transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
          &:hover a .es-icon i {
          color: #26c1fb;
          }
          &:hover a .es-title {
          color: #26c1fb;
          }
        }
        .es-cg-watch-shipment {
          grid-area: cg_watch_shipment; // 🗺
          transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
          &:hover a .es-icon i {
          color: #26c1fb;
          }
          &:hover a .es-title {
          color: #26c1fb;
          }
        }
      }
    }
    // COMPONENT GRID - SHIPMENT DOCUMENTS 📦
    .es-c-shipment-documents {
      .es-cg-shipment-documents {
        // 📰
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 0.2fr 0.5fr 0.3fr;
        grid-template-areas:
        "cg_pdf_icon cg_title cg_icons"
        "cg_pdf_icon cg_sub_title cg_icons";

        .es-cg-pdf-icon {
          grid-area: cg_pdf_icon; // 🗺
        }
        .es-cg-title {
          grid-area: cg_title; // 🗺
          font-size: 14px;
          color: #0033a1;
        }
        .es-cg-sub-title {
          grid-area: cg_sub_title; // 🗺
          font-size: 12px;
          color: #666;
          font-weight: 400;
        }
        .es-cg-icons {
          grid-area: cg_icons; // 🗺
        }
      }
    }
`;

const EchoShipOrganism = () => {
  return (

    <OrganismTrackShipmentRightContent>
      <div className="es-g-right-content">
        <div className="es-sg-right-content">
          <div className="es-sg-map-route">
            <div className="es-sg-title">Route Map</div>
            <Link to="/Shipments/ShipmentDetails/ShipmentRouteMap/">
            <div className="es-c-shipment-options">
              <img src="https://res.cloudinary.com/dj1xukpnf/image/upload/v1551922702/Screen_Shot_2019-03-06_at_3.15.44_PM.png" />
            </div>
            </Link>
          </div>
          <div className="es-sg-shipment-options">
            <div className="es-sg-title">Shipment Options</div>
            <div className="es-c-shipment-options">
              <div className="es-cg-shipment-options">
                <div className="es-cg-copy-shipment">
                  <a href="/">
                    <div className="es-title">Copy Shipment</div>
                    <div className="es-icon">
                      <i className="far fa-copy"/>
                    </div>
                  </a>
                </div>
                <div className="es-cg-share-bol">
                  <a href="/">
                    <div className="es-title">Share BOL</div>
                    <div className="es-icon">
                    <i className="far fa-envelope"></i>
                    </div>
                  </a>
                </div>
                <div className="es-cg-notify-pickup">
                  <a href="/">
                    <div className="es-title">Notify at Pickup</div>
                    <div className="es-icon">
                    <i class="far fa-bell"></i>
                    </div>
                  </a>
                </div>
                <div className="es-cg-watch-shipment">
                  <a href="/">
                    <div className="es-title">Unwatch Shipment</div>
                    <div className="es-icon">
                      <i className="fas fa-bookmark" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="es-sg-shipment-documents">
            <div className="es-sg-title">Documents</div>
            <div className="es-c-shipment-documents">
              <div className="es-cg-shipment-documents">
                <div className="es-cg-pdf-icon">
                  <svg
                    width={51}
                    height={62}
                    viewBox="0 0 51 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.34286 1.01444C1.09659 1.01444 0 2.04319 0 3.23209V59.7823C0 60.967 1.09989 61.9999 2.34286 61.9999H46.8571C48.1034 61.9999 49.2 60.9712 49.2 59.7823V13.2115H44.5143H39.8286C37.318 13.6718 35.67 12.115 35.1429 9.88506V1.01444H2.34286V1.01444ZM36.3143 2.12333C36.3143 0.932709 37.1348 0.653236 38.6571 1.01451L49.2 10.994C49.6266 11.4024 49.2844 12.1028 48.0286 12.1028H38.6571C37.4596 12.1028 36.3143 11.108 36.3143 9.88512V2.12333V2.12333Z"
                      fill="#E1E2E3"
                    />
                    <path
                      d="M6.24573 28.22H12.3957C14.2557 28.22 15.1857 29.1 15.1857 30.86V37.1C15.1857 38.86 14.2557 39.74 12.3957 39.74H8.94573V47H6.24573V28.22ZM12.4857 36.68V31.28C12.4857 30.92 12.4257 30.69 12.3057 30.59C12.1857 30.47 11.9457 30.41 11.5857 30.41H8.94573V37.58H11.5857C11.9457 37.58 12.1857 37.52 12.3057 37.4C12.4257 37.28 12.4857 37.04 12.4857 36.68ZM17.6436 28.22H24.1836C26.0436 28.22 26.9736 29.1 26.9736 30.86V44.36C26.9736 46.12 26.0436 47 24.1836 47H17.6436V28.22ZM24.2436 44V31.22C24.2436 30.86 24.1836 30.63 24.0636 30.53C23.9436 30.41 23.7036 30.35 23.3436 30.35H20.3736V44.87H23.3436C23.7036 44.87 23.9436 44.82 24.0636 44.72C24.1836 44.6 24.2436 44.36 24.2436 44ZM38.1369 30.41H32.6769V36.59H36.9969V38.72H32.6769V47H29.9469V28.22H38.1369V30.41Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="es-cg-title">BOL</div>
                <div className="es-cg-sub-title">Created Mar 07, 2019</div>
                <div className="es-cg-icons" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </OrganismTrackShipmentRightContent>

  )
}

export default EchoShipOrganism;